import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  fontSize: {
    desktop: '16px',
    tablet: '16px',
    mobile: '16px'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 5,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      dark: false,
      defaultCss: {
        backgroundImage: "url('/images/bg.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '40px 0 80px',
            widescreen: '40px 0 80px',
            desktop: '40px 40px 80px',
            tablet: '40px 40px 60px',
            mobile: '30px 20px 40px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 6,
                        fontSize: {
                          desktop: '14px',
                          tablet: '14px',
                          mobile: '12px'
                        },
                        margin: {
                          desktop: '0 0 10px',
                          tablet: '0 0 10px',
                          mobile: '0 0 10px'
                        },
                        text:
                          'Avoid expensive mistakes when buying real estate:'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 1,
                        fontSize: {
                          desktop: '32px',
                          tablet: '32px',
                          mobile: '20px'
                        },
                        margin: 0,
                        defaultCss: {
                          lineHeight: '1.2'
                        },
                        text:
                          'Get your personal financing concept within 24 hours, through which you get your dream property at best conditions'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '120px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '20px auto',
                          tablet: '20px auto',
                          mobile: '20px auto'
                        }
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          textAlign: 'center'
                        },
                        fontSize: {
                          desktop: '16px',
                          tablet: '16px',
                          mobile: '16px'
                        },
                        margin: {
                          desktop: '0 120px 20px',
                          tablet: '0 0 20px',
                          mobile: '0 0 10px'
                        },
                        text:
                          'Together with you, I create an <b>attractive financing plan</b> and handle the <b>complete</b> financing concept for your property.'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/525428323?=xdr4',
                        poster:
                          'https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/7ff06750-18b6-44db-b15e-8e7433e10b1f.gif?ClientID=vimeo-core-prod&Date=1622090987&Signature=2ed314588d256e041593be4aa3bdd497673e89d7'
                      }
                    },
                    {
                      classNames: 'is-hidden-mobile',
                      margin: '20px',
                      defaultCss: {
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      },
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/whofinance.png',
                            width: '200px'
                          }
                        },
                        {
                          type: 'image',
                          config: {
                            src: '/images/whofinance-video.png',
                            width: '300px'
                          }
                        }
                      ]
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          'Discover what really matters when financing a property and secure your professional support for all <u>important</u> choices.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Better conditions:</b> Through my extensive network, I will open doors that will save you money',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Quick results</b>: In our initial meeting, you will receive feedback on the general feasibility',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>More security</b>: With my financing concept, your process is predictable and manageable. You keep full control at all times',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        full: true,
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '10px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
