import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  fontSize: '18px',
  margin: {
    desktop: '0 0 30px',
    tablet: '0 0 30px',
    mobile: '0 0 30px'
  }
}
const itemIconConfig = {
  defaultCss: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    color: '#fff',
    width: '25px',
    height: '25px',
    backgroundColor: '#00305e',
    borderRadius: '50%'
  },
  icon: 'done',
  size: 2
}
const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/1.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 16px'
      },
      containers: [
        {
          type: 'text',
          config: {
            dark: true,
            margin: 0,
            size: 2,
            text: 'Die „Komplett-Lösung“ für Immobilienfinanzierungen',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            defaultCss: {
              color: 'primary',
              margin: '40px auto 30px'
            },
            height: 4
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            margin: {
              desktop: '0 0 40px',
              tablet: '0 0 40px',
              mobile: '0 0 40px'
            },
            text:
              'Ich weiß: Sie haben schon genug zu tun.<br/> Deshalb übernehme ich für Sie die <b>KOMPLETTE Abwicklung</b> mit Ihrer Bank!',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'Das beinhaltet:',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Kostenlose Analyse Ihrer Situation',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Entwicklung eines optimalen Finanzierungskonzepts',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Professionelle Betreuung während des kompletten Prozesses',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'Und das Beste daran…',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            'Sie profitieren von einer schnellen und persönlichen Unterstützung',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Sie erhalten Top-Konditionen',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Sie bekommen eine umfangreiche Marktübersicht',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          classNames: 'column is-12-mobile is-12-desktop pl-0 pr-0',
          containers: [
            {
              type: 'text',
              config: {
                dark: true,
                margin: '25px 0 50px',
                size: 4,
                text:
                  '➞ Ich berate Sie unabhängig und individuell - maßgeschneidert auf Ihre Situation'
              }
            },
            {
              type: 'cta',
              config: {
                margin: {
                  desktop: '10px 0 10px',
                  tablet: '10px 0 0',
                  mobile: '10px 0 0'
                },
                ...ctaButton,
                link: ''
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
