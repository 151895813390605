import { ContainerBox, Datenschutz } from '@peakconcepts/landingpage-kit'
import seoHeaderData from '../../content/data/de/seoHeader'

import leadData from './data/de/lead'
import imprintData from './data/de/imprint'
import policyData from './data/de/policy'

const data = [
  {
    path: '/impressum',
    headerEnabled: true,
    seoHeaderConfig: seoHeaderData.imprint,
    component: ContainerBox,
    data: {
      ...imprintData
    }
  },
  {
    path: '/datenschutz',
    headerEnabled: true,
    seoHeaderConfig: seoHeaderData.policy,
    component: Datenschutz,
    data: {
      ...policyData
    }
  },
  {
    id: 'Danke',
    path: '/danke',
    headerEnabled: true,
    seoHeaderConfig: seoHeaderData.thankYou,
    component: ContainerBox,
    data: {
      ...leadData
    }
  }
]

export default data
