import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemImageBoxClassNames = 'column is-12-mobile is-12-tablet is-4-desktop'
const itemTextBoxClassNames = 'column is-12-mobile is-12-tablet is-8-desktop'

const itemContainerConfig = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemTitleConfig = {
  fontSize: 18,
  size: 4,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const itemDescriptionConfig = {
  fontSize: 16,
  defaultCss: {
    fontStyle: 'italic'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 16px'
      },
      containers: [
        {
          containers: [
            {
              type: 'text',
              config: {
                margin: '0 0 40px',
                text: 'What customers say about me',
                size: 2
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 60px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              defaultCss: {
                boxShadow: '0 1px 5px rgb(0 0 0 / 20%)',
                position: 'relative',
                backgroundColor: '#fff'
              },
              padding: {
                desktop: '40px 20px',
                tablet: '40px 20px',
                mobile: '40px 0'
              },
              containers: [
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/2.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Yannis T. on 10/04/2020:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„An excellent consultant! Ms. Sabine Krauß was the consultant in our recent real estate purchase and assisted us with our mortgage. Thanks to her we got a mortgage with very good terms. We were extremely happy with her tireless effort, her willingness to explain every step (and its consequences) with meticulous detail, and hugely appreciated her availability and the speed with which she was able to get things done. A very competent professional, the best anyone would ever hope to have in a serious investment such as that of a new house.  Competence in finding and securing the best offer; willingness to explain in great detail without consideration of how long it would take; availability and speed.”'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/3.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Familiy T. on 01/01/2020:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Very good! As an expat, it is very challenging to get a good finance consultation. I find Ms. Krauss as very suited in my case, as it was very complicated. She has good know how and comes up with different options. She speaks English and very easy to communicate. She is very dedicated and hardworking, she was reachable whenever we had questions. She guides through the process and capable of obtaining good result.”'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/4.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Edgar A. on 12/20/2019:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„100% recommended! Real state acquisition. Finance process. As a non-German citizen, real estate finance is quite limited and complicated. Thanks to her advice and support we managed to make our dreams come true and further on her assistance to go through the process and all credit documents ease our decision making and field confidence with our House acquisition. We can ensure that her support and Financing knowledge help foreigners who want to make Germany their new home. Nothing to complain about.”'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: 'text',
              config: {
                text:
                  'More opinions and reviews can be found at <u><a href="https://www.whofinance.de/berater/sabine-krauss/" target="_blank">Who Finance</a></u>',
                margin: {
                  desktop: '30px 0 0',
                  tablet: '30px 0 0',
                  mobile: '30px 0 0'
                },
                defaultCss: {
                  textAlign: 'center'
                }
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
