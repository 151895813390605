import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../colors'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: colors.secondaryColor,
    overflow: 'hidden'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      defaultCss: {
        zIndex: '0'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            margin: '0 0 40px 0',
            defaultCss: {
              color: '#fff'
            },
            text: 'Was macht mich so besonders?'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 30px',
              mobile: '30px auto 30px'
            }
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Die meisten Banken und Kreditinstitute arbeiten sehr eingeschränkt. Angestellte Bankberater haben oft nur eine begrenzte Auswahl an Finanzierungsprodukten, da sie ihre hauseigenen Produkte verkaufen sollen.<br/><br/>Ich arbeite als freie Beraterin und kann für Sie aus einem riesigen Pool an Anbietern vergleichen und das <b><u>beste</u></b> Konzept erstellen.<br/><br/>Wenn Sie herausfinden möchten, wie Sie Ihre Immobilie und kürzester Zeit optimal finanzieren, dann klicken Sie jetzt auf den Button, um sich ein persönliches Beratungsgespräch zu sichern.',
            defaultCss: {
              color: '#fff',
              textAlign: 'center'
            },
            margin: {
              desktop: '0 80px 60px',
              tablet: '0 0 60px',
              mobile: '0 0 40px'
            }
          }
        },
        {
          type: 'cta',
          config: {
            dark: true,
            margin: {
              desktop: '10px 0 25px',
              tablet: '10px 0 0',
              mobile: '10px 0 0'
            },
            ...ctaButton
          }
        },
        {
          defaultCss: {
            position: 'absolute',
            bottom: '-80px',
            zIndex: '-1',
            opacity: '0.15'
          },
          containers: [
            {
              type: 'image',
              config: {
                src: '/images/city.png'
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
