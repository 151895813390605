import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemNumberBoxClassNames =
  'column is-2-mobile is-1-tablet is-1-desktop pt-0 pb-0'
const itemTextBoxClassNames =
  'column is-10-mobile is-11-tablet is-11-desktop pl-0 pb-6 pt-0'

const itemDotsContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            margin: {
              fullHd: '0 0 40px',
              widescreen: '0 0 40px',
              desktop: '0 0 40px',
              tablet: '0 0 20px',
              mobile: '0 0 20px'
            },
            size: 2,
            text: 'How does the cooperation with me work?',
            defaultCss: {
              marginBottom: '40px',
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            margin: {
              fullHd: '30px auto',
              widescreen: '30px auto',
              desktop: '30px auto',
              tablet: '20px auto',
              mobile: '20px auto'
            },
            defaultCss: {
              color: 'primary'
            },
            height: 4
          }
        },
        {
          padding: {
            fullHd: '70px 0 0 0',
            widescreen: '70px 0 0 0',
            desktop: '70px 0 0 0',
            tablet: '30px 0 0 0',
            mobile: '30px 0 0 0'
          },
          classNames: 'columns is-multiline is-mobile',
          containers: [
            {
              defaultCss: {
                display: 'flex'
              },
              containers: [
                {
                  classNames: itemNumberBoxClassNames,
                  ...itemDotsContainer,
                  containers: [
                    {
                      type: 'dots',
                      config: {
                        color: 'rgb(255,170,0)',
                        dotConfig: {
                          dotSize: 2
                        }
                      }
                    }
                  ]
                },
                {
                  classNames: itemTextBoxClassNames,
                  ...itemTextBoxContainer,
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'In a personal meeting, we find out together what is really important to you when it comes to real estate financing. You will receive an in-depth consultation that is <u>individually tailored to your situation</u>. In addition, you will get the professional opinion of an experienced expert on your real estate financing.'
                      }
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'In the second consultation, we will design a clear concept <u>based on your goals</u> on how to optimally finance your property. I will show you different possibilities and walk you through alternative ways which might be suitable for you. In order to give you an overview, I will present all the advantages and disadvantages of the respective financing concepts and give you serious and reliable advice regarding the further course of action.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            showLines: false,
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'After we have worked out the optimal process together, I will help you with the complete implementation in a timely manner. I take over the full administrative effort and got your back. Of course, you enjoy absolute transparency during the <u>complete process</u>, because I inform you about every step and discuss it with you. Finally, you make a decision together with me and start financing your dream property.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          classNames: 'column is-12-mobile is-12-desktop pl-0 pr-0',
          containers: [
            {
              type: 'text',
              config: {
                margin: '0',
                text:
                  '<b>IMPORTANT:</b> My service is 100% free of charge for you until closing. This is because I am ONLY paid for my success. Only when you have decided on an option that you are truly satisfied with, and it is approved by the bank or insurance company, I will receive a commission from the financing partner.'
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
