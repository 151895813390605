import { HeaderInterface } from '@peakconcepts/landingpage-kit/lib'
import generalData from './data/en/general'
import colors from '../data/colors'

const header: HeaderInterface = {
  withContainer: true,
  containerConfig: {
    padding: {
      fullHd: '20px 0',
      widescreen: '20px 0',
      desktop: '20px 0',
      tablet: '20px 0',
      mobile: '10px 0'
    },
    defaultCss: {
      backgroundColor: colors.secondaryColor
    }
  },
  brandConfig: {
    className: 'ml-0',
    // center brand only on mobile (works because menuContainer is in burgerMenu on mobile)
    defaultCss: { justifyContent: 'center' },
    imageContainer: {
      className: 'p-0'
    },
    image: {
      src: '/images/logo.jpg',
      width: '60px',
      defaultCss: {
        // bulma sets max-height
        maxHeight: 'unset !important'
      }
    }
  },
  burgerConfig: {
    hide: true
  }
}

const data = {
  config: {
    gtmID: generalData.gtmID,
    theme: {
      primary: generalData.theme.primary,
      secondary: generalData.theme.secondary
    }
  },
  footer: {
    copyright: 'Copyright © 2021. All rights reserved.',
    dark: true,
    backgroundColor: colors.secondaryColor,
    image: {
      width: '100px',
      src: '/images/logo.jpg'
    },
    links: [
      {
        title: 'Home',
        to: '/start'
      },
      {
        title: 'Imprint',
        to: '/impressum'
      },
      {
        title: 'Policy',
        to: '/datenschutz'
      }
    ],
    text:
      'This site is not a part of the Facebook TM website or Facebook TM Inc. Additionally, this site is NOT endorsed by FacebookTM in any way. FACEBOOK TM is a trademark of FACEBOOK TM, Inc.'
  },
  scrollToTop: {
    pixelFromEnd: 400
  },
  header,
  calendly: {
    pageSettings: {
      backgroundColor: generalData.calendly.pageSettings.backgroundColor,
      hideEventTypeDetails:
        generalData.calendly.pageSettings.hideEventTypeDetails,
      hideLandingPageDetails:
        generalData.calendly.pageSettings.hideLandingPageDetails,
      primaryColor: generalData.calendly.pageSettings.primaryColor,
      textColor: generalData.calendly.pageSettings.textColor
    },
    url: generalData.calendly.url
  }
}

export default data
