import colors from '../../../data/colors'

const data = {
  /** Theme */
  theme: {
    primary: colors.primaryColor,
    secondary: colors.secondaryColor
  },
  /** TagManager */
  gtmID: 'GTM-WGXR5S9',
  /** Calendly */
  calendly: {
    pageSettings: {
      backgroundColor: 'ffffff',
      hideEventTypeDetails: false,
      hideLandingPageDetails: false,
      primaryColor: colors.primaryColor,
      textColor: '000000'
    },
    url: 'https://calendly.com/s-krauss/kostenloses-qualifizierungsgesprach'
  }
}

export default data
