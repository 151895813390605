const data = {
  sectionConfig: {
    padding: {
      desktop: '40px 0',
      tablet: '40px 0',
      mobile: '40px 0'
    }
  },
  company: 'Sabine Krauß<br/> Tannhäuserring 60<br/> 68199 Mannheim',
  provider: 'ALL-INKL',
  customCookieScriptTag: {
    id: 'CookieDeclaration',
    src:
      'https://consent.cookiebot.com/12d530c7-0664-439a-b573-a78c331cf92e/cd.js',
    type: 'text/javascript',
    async: true
  }
}

export default data
