import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '16px',
        mobile: '16px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-full',
              defaultCss: {
                backgroundColor: '#00305e'
              },
              padding: {
                fullHd: '80px 0',
                widescreen: '80px 0',
                desktop: '80px 40px',
                tablet: '60px 40px',
                mobile: '40px 20px'
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    fontSize: {
                      desktop: '32px',
                      tablet: '24px',
                      mobile: '16px'
                    },
                    margin: {
                      desktop: '0 0 30px',
                      tablet: '0 0 30px',
                      mobile: '0 0 30px'
                    },
                    defaultCss: {
                      color: '#fff'
                    },
                    text: 'Your request was successful ✅'
                  }
                },
                {
                  type: 'text',
                  config: {
                    fontSize: {
                      desktop: '20px',
                      tablet: '20px',
                      mobile: '16px'
                    },
                    margin: { desktop: '0', tablet: '0', mobile: '0' },
                    defaultCss: {
                      textAlign: 'center',
                      color: '#fff'
                    },
                    text:
                      'At this point I would like to thank you for your trust!'
                  }
                }
              ]
            },
            {
              classNames: 'column is-full',
              padding: {
                desktop: '80px 40px',
                tablet: '60px 30px',
                mobile: '40px 15px'
              },
              defaultCss: {
                boxShadow: '0 1px 5px rgb(0 0 0 / 20%)',
                media: [
                  {
                    breakpoint: 'mobile',
                    css: { boxShadow: 'none' }
                  }
                ]
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    text: 'What happens now?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    fontSize: {
                      desktop: '20px',
                      tablet: '20px',
                      mobile: '18px'
                    },
                    margin: {
                      desktop: '0 100px 40px',
                      tablet: '0 60px 40px',
                      mobile: '0 20px 40px'
                    },
                    defaultCss: {
                      textAlign: 'center'
                    },
                    text:
                      'You will get the opportunity to ask all your questions <u>completely free</u> of charge and to find out in detail about working with me.<br/><br/>In order to answer your questions as best as possible, you will receive a phone call at the agreed time.<br/><br/>Based on our conversation we will find out if a cooperation <u>makes sense</u>.<br/><br/>During our first meeting I will take time exclusively for you and approach your situation <b>individually</b>.<br/><br/>That way you will get a closer to your goal.<br/><br/><b>I look forward to meeting you.</b><br/><br/><br/>Best regards<br/>Yours, Sabine Krauß'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
