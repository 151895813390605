// @ts-nocheck
import { Content, ContainerBox } from '@peakconcepts/landingpage-kit'
import colors from './content/data/colors'

// Defaults
import defaultSettings from './content/default/en_defaultSettings'
import defaultPages from './content/default/en_defaultPages'

// Content Data
import seoHeaderData from './content/data/en/seoHeader'
import shortHeroHeaderData from './content/data/en/hero'
import openingData from './content/data/en/opening'
import twoOptionsData from './content/data/en/twoOptions'
import howItWorksData from './content/data/en/howItWorks'
import solutionData from './content/data/en/solution'
import advantagesData from './content/data/en/advantages'
import infoData from './content/data/en/info'
import resultsData from './content/data/en/results'
import aboutUsData from './content/data/en/aboutUs'
import quoteData from './content/data/en/quote'
import processData from './content/data/en/process'
import faqsData from './content/data/en/faqs'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      path: '/start',
      headerEnabled: true,
      headerConfig: {
        positionType: 'absolute',
        fixed: false,
        backgroundColor: colors.secondaryColor,
        linkTo: '/start'
      },
      seoHeaderConfig: seoHeaderData.main,
      sections: [
        {
          component: ContainerBox,
          data: {
            ...shortHeroHeaderData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...openingData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...twoOptionsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...howItWorksData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...solutionData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...advantagesData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...quoteData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...aboutUsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...infoData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...resultsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...processData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...faqsData
          }
        }
      ]
    },
    ...defaultPages
  ]
}
export default content
