import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../colors'
import ctaButton from './ctaButton'

const itemCardConfig = {
  defaultCss: {
    boxShadow: '0 1px 5px rgb(0 0 0 / 10%)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '60px 0 0',
    tablet: '60px 0 0',
    mobile: '60px 0 0'
  }
}
const itemTitleConfig = {
  size: 4,
  margin: {
    desktop: '20px 0 20px',
    tablet: '20px 0 20px',
    mobile: '20px 0 20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#000',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: colors.primaryColor
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: colors.secondaryColor
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: '#fff'
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            text: 'In 3 steps to your real estate financing'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 40px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'date_range',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              '<span>Step 1:</span><br/> Secure an appointment'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Arrange a free initial telephone consultation now. To do so, use the appointment calendar to book a free time slot that suits you best.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'call',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: '<span>Step 2:</span><br/> Analysis meeting'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Together, we analyze your current situation and answer all your questions about working together in a video call.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'trending_up',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              '<span>Step 3:</span><br/> Individual implementation'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'You will receive a solution individually adapted to your situation in order to optimally finance your property.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 4,
                    defaultCss: {
                      color: '#fff'
                    },
                    margin: {
                      desktop: '50px 0 20px',
                      tablet: '50px 0 20px',
                      mobile: '30px 0 20px'
                    },
                    text:
                      'Do you want to finance your property at best conditions?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center',
                      color: '#fff'
                    },
                    text:
                      'Then click the button now and book a free consultation with an expert. In our free and non-binding initial consultation we will find out together, if and how I can help you.'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: true,
                    text: ctaButton.text,
                    subline: ctaButton.subline,
                    link: ctaButton.link,
                    margin: {
                      desktop: '30px 0 0',
                      tablet: '30px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
