import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 20px 20px 0',
    tablet: '0 20px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-10-tablet is-8-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'left'
                    },
                    size: 2,
                    text:
                      'What do you need to consider when buying real estate?'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '20px 0 30px',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'To finance a house or apartment, there are many options.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      "However, maybe that's exactly why you feel overwhelmed, because you really want to do everything right..."
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      "<b>… but you simply don't know which way is the best for you."
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      "And you don't want to spend all the effort and time you have to invest, while you run from bank to bank to get offers?"
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'At the same time you would like to finance your real estate <b>as optimal as possible</b>?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '➞ Then you, just like most buyers, are missing a system, which shows you the right steps in the right order.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b><u>The good news:</u></b><br/>The financing of a property can be very uncomplicated and timesaving <u>if you know how to proceed</u>.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      "<b>And that's exactly what I'm here to help you with.</b>"
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      "I don't just show you the best way to go, I go through it together with you and support you during the whole process."
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
