import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'
import colors from '../colors'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundColor: colors.secondaryColor,
    overflow: 'hidden'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      defaultCss: {
        zIndex: '0'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            margin: '0 0 40px 0',
            defaultCss: {
              color: '#fff'
            },
            text: 'What makes me so unique?'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '120px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 30px',
              mobile: '30px auto 30px'
            }
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Most banks and credit institutions operate on a very limited basis. Employed bank consultants often have only a restricted choice of financing options, as they are supposed to sell their in-house products.<br/><br/> I work as a freelance consultant and can compare and create the <b>best concept</b> for you from a huge pool of providers.',
            defaultCss: {
              color: '#fff',
              textAlign: 'center'
            },
            margin: {
              desktop: '0 80px 60px',
              tablet: '0 0 60px',
              mobile: '0 0 40px'
            }
          }
        },
        {
          type: 'cta',
          config: {
            dark: true,
            margin: {
              desktop: '10px 0 25px',
              tablet: '10px 0 0',
              mobile: '10px 0 0'
            },
            ...ctaButton
          }
        },
        {
          defaultCss: {
            position: 'absolute',
            bottom: '-80px',
            zIndex: '-1',
            opacity: '0.15'
          },
          containers: [
            {
              type: 'image',
              config: {
                src: '/images/city.png'
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
