const general = {
  title: 'Sabine Krauß » Real estate financing.',
  description:
    'How to finance your dream property within 4 weeks with best conditions',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'images/share.jpg',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  imprint: {
    ...general,
    title:
      'Impressum » How to finance your dream property within 4 weeks with best conditions'
  },
  policy: {
    ...general,
    title:
      'Datenschutz » How to finance your dream property within 4 weeks with best conditions'
  },
  thankYou: {
    ...general,
    title:
      'Danke » How to finance your dream property within 4 weeks with best conditions'
  }
}

export default data
