import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('/images/5.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        desktop: '120px 0',
        tablet: '120px 0',
        mobile: '120px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            dark: true,
            defaultCss: {
              textAlign: 'center',
              fontWeight: '500'
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            size: 4,
            text: 'Egal, ob für Eigennutzer oder Kapitalanleger'
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            defaultCss: {
              textAlign: 'center'
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            size: 4,
            text:
              'Ich zeige Ihnen, wie Sie den Traum von Wohneigentum kostengünstig verwirklichen'
          }
        }
      ]
    }
  ]
}

export default data
