import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemNumberBoxClassNames =
  'column is-2-mobile is-4-tablet is-offset-1-desktop is-1-desktop'
const itemTextBoxClassNames = 'column is-10-mobile is-8-tablet is-9-desktop'

const itemNumberBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    media: [
      {
        breakpoint: 'tablet',
        css: { justifyContent: 'flex-start' }
      }
    ]
  }
}

const itemContainerConfig = {
  defaultCss: {
    margin: '0 0 10px !important',
    media: [
      {
        breakpoint: 'tablet',
        css: { margin: '0 !important' }
      }
    ]
  }
}

const itemNumberConfig = {
  size: 4,
  fontSize: {
    fullHd: '96px',
    widescreen: '96px',
    desktop: '96px',
    tablet: '60px',
    mobile: '36px'
  },
  margin: {
    desktop: '0 0 0 -70px',
    tablet: '0 0 0 -10px',
    mobile: '0 0 0 -10px'
  },
  defaultCss: {
    color: '#fa0',
    lineHeight: '1',
    textAlign: 'left'
  }
}
const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.95)), url('/images/6.png')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            margin: 0,
            size: 2,
            text: 'Ihre Vorteile auf einen Blick',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 30px',
              mobile: '30px auto 30px'
            },
            defaultCss: {
              color: 'primary'
            },
            height: 4
          }
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '01'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'Volle Kontrolle'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Durch mein Konzept erhalten Sie einen Röntgenblick auf alle Kosten. Denn ich stelle Ihnen sämtliche Zahlen, Daten & Fakten zu Verfügung. Sie behalten zu jeder Zeit den Überblick und besitzen einen klaren Plan, durch den Sie genau wissen, was auf Sie zukommt.'
                  }
                }
              ]
            }
          ]
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '02'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'Mehr Sicherheit'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Durch die Zusammenarbeit mit mir als Expertin beseitigen Sie das Risiko vollständig, teure Fehler zu begehen. Sie profitieren von der Erfahrung aus über 30 Jahren in der Finanzbranche, davon 15 Jahren in der Baufinanzierung und nutzen Methoden, die speziell für Immobilienkäufer konzipiert wurde.'
                  }
                }
              ]
            }
          ]
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '03'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'Bestkonditionen'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Dank meinem Netzwerk aus verschiedenen Banken und Versicherungen erhalten Sie ideale Konditionen, für eine optimale Immobilienfinanzierung. Sie sparen viel Geld, da Sie aus einer Vielzahl an Anbietern die günstigste Bank wählen können.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
