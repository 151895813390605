import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const titleConfig = {
  size: 5,
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  },
  defaultCss: {
    textAlign: 'left'
  }
}

const descriptionConfig = {
  fontSize: {
    desktop: '18px',
    tablet: '16px',
    mobile: '14px'
  },
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0 0 40px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Häufig gestellte Fragen',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            text: 'Warum bekomme ich hier bessere Konditionen?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Ich arbeite zu 100 % unabhängig und habe dadurch die Möglichkeit, aus allen Anbietern den besten für Sie herauszusuchen.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'Was kostet der Service?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Grundsätzlich ist mein Service für Sie komplett kostenlos. Während der Bankberater sein Gehalt erhält, erhalte ich im Erfolgsfall eine Provision – mit dem weiteren Vorteil: ich arbeite unabhängig und Sie erhalten dadurch das beste Finanzierungskonzept. Die Provision zahlen nicht Sie, sondern die finanzierende Bank. Daher ist der Service für Sie 100 % kostenfrei.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'Welche Voraussetzungen müssen Sie erfüllen?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Für eine Zusammenarbeit müssen Sie eine positive Schufa-Auskunft haben und eine Aufenthaltsgenehmigung in Deutschland besitzen.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Wie hoch kann der Kredit werden, den ich für ein Haus bekomme?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Die Höhe Ihres Kreditvolumens ist immer individuell von der jeweiligen Bank und Ihrer persönlichen Situation abhängig. Gerne unterstütze ich Sie beim Finden einer Immobilie, die ideal zu Ihrer finanziellen Situation passt und entwerfe hierzu ein maßgeschneidertes Finanzierungskonzept, das genau auf Ihre Wünsche zugeschnitten ist.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'Ist eine Finanzierung auch ohne Eigenkapital möglich?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Grundsätzlich ja, allerdings benötige ich genaue Daten von Ihnen, um eine sichere Aussage treffen zu können. Welcher Plan eine Finanzierung ohne Eigenkapital ermöglicht und welche Konditionen Sie erhalten können, erfahren Sie in unserem Erstgespräch.',
            ...descriptionConfig,
            margin: {
              desktop: '0 0 20px',
              tablet: '0 0 20px',
              mobile: '0 0 20px'
            }
          }
        },
        {
          type: 'cta',
          config: {
            dark: false,
            text: ctaButton.text,
            subline: ctaButton.subline,
            link: ctaButton.link,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
