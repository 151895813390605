import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 20px 20px 0',
    tablet: '0 20px 20px 0',
    mobile: '0 0 20px 0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-10-tablet is-8-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'left'
                    },
                    size: 2,
                    text: 'Was müssen Sie beim Immobilienkauf beachten?'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '20px 0 30px',
                      tablet: '20px 0 30px',
                      mobile: '20px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Um ein Haus oder eine Wohnung zu finanzieren, gibt es viele Möglichkeiten.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Doch vielleicht fühlen Sie sich genau deshalb überfordert, denn Sie möchten unbedingt alles richtig machen…'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b>… wissen aber einfach nicht, welcher Weg der Beste für Sie ist.</b>'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Außerdem haben Sie keine Lust auf den hohen Aufwand und die ganze Zeit, die Sie investieren müssen, während Sie von Bank zu Bank laufen und Angebote einholen?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Gleichzeitig möchten Sie aber die Finanzierung Ihrer Immobilie <b>so optimal wie nur möglich</b> gestalten?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '➞ Dann fehlt Ihnen genau wie den meisten Käufern <u>ein System</u>, das Ihnen die richtigen Schritte in der richtigen Reihenfolge zeigt.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      '<b><u>Die gute Nachricht:</u></b><br/>Die Finanzierung einer Immobilie kann sehr unkompliziert und zeitsparend ablaufen, <u>wenn man weiß, wie man vorgehen muss</u>.'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text: '<b>Genau dabei helfen wir Ihnen.</b>'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Ich zeige Ihnen nicht nur den besten Weg, ich gehe diesen mit Ihnen gemeinsam und unterstütze Sie während des ganzen Prozesses.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
