import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemImageBoxClassNames = 'column is-12-mobile is-12-tablet is-4-desktop'
const itemTextBoxClassNames = 'column is-12-mobile is-12-tablet is-8-desktop'

const itemContainerConfig = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemTitleConfig = {
  fontSize: 18,
  size: 4,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const itemDescriptionConfig = {
  fontSize: 16,
  defaultCss: {
    fontStyle: 'italic'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 16px'
      },
      containers: [
        {
          containers: [
            {
              type: 'text',
              config: {
                margin: '0 0 40px',
                text: 'Das sagen Kunden über mich',
                size: 2
              }
            },
            {
              type: 'hr',
              config: {
                height: 4,
                width: '120px',
                defaultCss: {
                  color: 'primary'
                },
                margin: {
                  desktop: '30px auto 60px',
                  tablet: '30px auto 50px',
                  mobile: '30px auto 40px'
                }
              }
            },
            {
              defaultCss: {
                boxShadow: '0 1px 5px rgb(0 0 0 / 20%)',
                position: 'relative',
                backgroundColor: '#fff'
              },
              padding: {
                desktop: '40px 20px',
                tablet: '40px 20px',
                mobile: '40px 0'
              },
              containers: [
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/2.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Alina L. am 14.04.20:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Der Anlass der Beratung war eine Baufinanzierung. Bei der Beratung ging es darum, welche Finanzierung zu uns und unseren Lebensvorstellungen am besten passt. Auch Wissenslücken unsererseits konnten von der Finanzberaterin gefüllt werden. Die Beratung verlief einwandfrei und wurde stetig per Mail dokumentiert. Wir sind sehr zufrieden und würden Frau Krauß als Finanzberaterin weiterempfehlen. Die Finanzberaterin konnte auf alle Fragen antworten und war stets erreichbar. Sie zeichnet sich durch Ihre Eigeninitiative, allumfassendes Wissen im Finanzsektor und einen freundlichen Umgang aus.”'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/3.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'Anna E. am 06.04.20:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Sie hat unseren Traum wahr werden lassen!! 9 Tage von der ersten Kontaktaufnahme bis zur Kreditzusage (Baufinanzierung)!! Durch Ihre schnelle Kontaktaufnahme, Ihre ausführliche und auch für uns "Laien" verständliche Beratung 24/7 und Ihr unermüdliches Engagement, kamen wir rasend schnell zu unserem Darlehensvertrag. Frau Krauss überzeugt durch Ihre äußerst kompatible, flexible, vertrauenswürdige und immer freundliche Art. Wir haben Ihr sehr viel zu verdanken und würden sie jederzeit weiterempfehlen. Das hat mir gut gefallen: Schnelligkeit, Erreichbarkeit, Freundlichkeit, Know-how Schnelligkeit.”'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-marginless',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemImageBoxClassNames,
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/4.jpg',
                            width: '100%'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: 'R. S. am 16.01.21:'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              '„Frau Krauß hat uns bei einer Baufinanzierung beraten. Wir finden Frau Krauß sehr kompetent und zuverlässig. Beratungstermine wurden sehr schnell gegeben. Emails wurden sofort beantwortet. Sie war uns immer erreichbar und unsere Fragen wurden sehr klar beantwortet. Die Finanzierungsangebote, -zinsen und -konditionen finden wir sehr gut. Gemäß ihrer Empfehlung haben wir ein Finanzierungsangebot angenommen und sind sehr zufrieden. Wir würden sie sehr gerne weiterempfehlen.”'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              type: 'text',
              config: {
                text:
                  'Weitere Meinungen und Bewertungen finden Sie unter <u><a href="https://www.whofinance.de/berater/sabine-krauss/" target="_blank">Who Finance</a></u>',
                margin: {
                  desktop: '30px 0 0',
                  tablet: '30px 0 0',
                  mobile: '30px 0 0'
                },
                defaultCss: {
                  textAlign: 'center'
                }
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
