import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  fontSize: '18px',
  margin: {
    desktop: '0 0 30px',
    tablet: '0 0 30px',
    mobile: '0 0 30px'
  }
}
const itemIconConfig = {
  defaultCss: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    color: '#fff',
    width: '25px',
    height: '25px',
    backgroundColor: '#00305e',
    borderRadius: '50%'
  },
  icon: 'done',
  size: 2
}
const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/images/1.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 16px'
      },
      containers: [
        {
          type: 'text',
          config: {
            dark: true,
            margin: 0,
            size: 2,
            text: 'The „complete solution“ for real estate financing',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            defaultCss: {
              color: 'primary',
              margin: '40px auto 30px'
            },
            height: 4
          }
        },
        {
          type: 'text',
          config: {
            dark: true,
            margin: {
              desktop: '0 0 40px',
              tablet: '0 0 40px',
              mobile: '0 0 40px'
            },
            text:
              "I know: You already have enough to do.<br/> That's why I take over the <b>COMPLETE</b> processing with your bank for you!",
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'That includes:',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'A free analysis of your situation',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Development of an optimal financing concept',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'Professional support during the whole process',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-6-tablet is-6-desktop',
              containers: [
                {
                  defaultCss: {
                    backgroundColor: 'white',
                    height: '100%'
                  },
                  padding: {
                    desktop: '30px',
                    tablet: '30px',
                    mobile: '30px 30px 10px 30px'
                  },
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 4,
                        text: 'And the best part…',
                        margin: {
                          desktop: '0 0 50px',
                          tablet: '0 0 50px',
                          mobile: '0 0 40px'
                        },
                        defaultCss: {
                          textAlign: 'left'
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'You benefit from fast and personal support',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'You receive top conditions',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text: 'You get a detailed market overview',
                          ...itemDescriptionConfig
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          classNames: 'column is-12-mobile is-12-desktop pl-0 pr-0',
          containers: [
            {
              type: 'text',
              config: {
                dark: true,
                margin: '25px 0 50px',
                size: 4,
                text:
                  '➞ I advise you independently and individually - tailored to your situation'
              }
            },
            {
              type: 'cta',
              config: {
                margin: {
                  desktop: '10px 0 10px',
                  tablet: '10px 0 0',
                  mobile: '10px 0 0'
                },
                ...ctaButton,
                link: ''
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
