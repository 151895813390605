import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import colors from '../colors'
import ctaButton from './ctaButton'

const itemCardConfig = {
  defaultCss: {
    boxShadow: '0 1px 5px rgb(0 0 0 / 10%)',
    position: 'relative',
    padding: '50px 30px 30px',
    backgroundColor: '#fff'
  },
  margin: {
    desktop: '60px 0 0',
    tablet: '60px 0 0',
    mobile: '60px 0 0'
  }
}
const itemTitleConfig = {
  size: 4,
  margin: {
    desktop: '20px 0 20px',
    tablet: '20px 0 20px',
    mobile: '20px 0 20px'
  }
}

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'center'
  }
}

const itemIconContainer = {
  defaultCss: {
    position: 'absolute',
    width: '100%',
    top: '-40px',
    left: '0',
    textAlign: 'center'
  }
}

const itemIconConfig = {
  size: 8,
  margin: {
    desktop: '0 auto'
  },
  defaultCss: {
    color: '#000',
    margin: 'auto',
    width: '80px',
    height: '80px',
    padding: '20px',
    borderRadius: '50%',
    background: colors.primaryColor
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    background: colors.secondaryColor
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '80px 40px',
        mobile: '60px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            size: 2,
            defaultCss: {
              color: '#fff'
            },
            margin: {
              desktop: '0',
              tablet: '0',
              mobile: '0'
            },
            text: 'In 3 einfachen Schritten zur Immobilienfinanzierung'
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 40px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'date_range',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: '<span>Schritt 1:</span><br/> Termin sichern'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Vereinbaren Sie jetzt ein kostenloses telefonisches Erstgespräch. Buchen Sie hierzu über den Terminkalender ein freies Zeitfenster, das Ihnen am besten passt.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'call',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text: '<span>Schritt 2:</span><br/> Analysegespräch'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Gemeinsam analysieren wir Ihre aktuelle Situation und beantworten in einem Videogespräch all Ihre Fragen zur Zusammenarbeit.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames:
                'column is-12-mobile is-12-tablet is-4-desktop is-flex',
              containers: [
                {
                  ...itemCardConfig,
                  containers: [
                    {
                      containers: [
                        {
                          ...itemIconContainer,
                          containers: [
                            {
                              type: 'textWithIcon',
                              config: {
                                iconConfig: {
                                  icon: 'trending_up',
                                  ...itemIconConfig
                                }
                              }
                            }
                          ]
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemTitleConfig,
                            text:
                              '<span>Schritt 3:</span><br/> Individuelle Umsetzung'
                          }
                        },
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Sie bekommen eine auf Ihre Situation individuell angepasste Lösung, um Ihre Immobilie optimal zu finanzieren.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              classNames: 'column is-full',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 4,
                    defaultCss: {
                      color: '#fff'
                    },
                    margin: {
                      desktop: '50px 0 20px',
                      tablet: '50px 0 20px',
                      mobile: '30px 0 20px'
                    },
                    text:
                      'Wollen Sie Ihre Immobilie zu Best-Konditionen finanzieren?'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      textAlign: 'center',
                      color: '#fff'
                    },
                    text:
                      'Dann klicken Sie jetzt auf den Button und buchen Sie ein kostenloses Beratungsgespräch mit einer Expertin. In unserem kostenlosen und unverbindlichen Erstgespräch finden wir gemeinsam heraus, ob und inwiefern ich Ihnen helfen kann.'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    dark: true,
                    text: ctaButton.text,
                    subline: ctaButton.subline,
                    link: ctaButton.link,
                    margin: {
                      desktop: '30px 0 0',
                      tablet: '30px 0 0',
                      mobile: '30px 0 0'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
