import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemNumberBoxClassNames = 'column is-2-mobile is-1-tablet is-1-desktop'
const itemTextBoxClassNames = 'column is-10-mobile is-11-tablet is-11-desktop'

const itemNumberBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    media: [
      {
        breakpoint: 'mobile',
        css: { justifyContent: 'flex-start' }
      }
    ]
  }
}

const itemContainerConfig = {
  defaultCss: {
    margin: '0 0 10px !important',
    media: [
      {
        breakpoint: 'tablet',
        css: { margin: '0 !important' }
      }
    ]
  }
}

const itemNumberConfig = {
  size: 4,
  fontSize: {
    fullHd: '80px',
    widescreen: '80px',
    desktop: '60px',
    tablet: '60px',
    mobile: '60px'
  },
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  },
  defaultCss: {
    color: 'rgba(255, 170, 0, 0.75)',
    lineHeight: '1',
    textAlign: 'left'
  }
}
const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  },
  defaultCss: {
    media: [
      {
        breakpoint: 'mobile',
        css: { color: '#fff' }
      }
    ]
  }
}

const data: ContainerBoxInterface = {
  dark: {
    desktop: false,
    tablet: true,
    mobile: true
  },
  defaultCss: {
    objectFit: 'cover',
    backgroundPosition: 'center',
    background:
      "linear-gradient(rgba(0,48,94,0.95), rgba(0,48,94,0.95)), url('/images/1.jpg')"
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline is-mobile is-centered',
          containers: [
            {
              classNames: 'column is-12-mobile is-10-tablet',
              padding: {
                desktop: '40px',
                tablet: '40px',
                mobile: '16px'
              },
              defaultCss: {
                backgroundColor: 'white',
                media: [
                  {
                    breakpoint: 'mobile',
                    css: { background: 'none' }
                  }
                ]
              },
              containers: [
                {
                  type: 'text',
                  config: {
                    margin: 0,
                    size: 2,
                    text: 'You have 2 options:',
                    defaultCss: {
                      textAlign: 'center',
                      media: [
                        {
                          breakpoint: 'mobile',
                          css: { color: '#fff' }
                        }
                      ]
                    }
                  }
                },
                {
                  type: 'hr',
                  config: {
                    defaultCss: {
                      color: 'primary',
                      marginTop: '30px'
                    },
                    height: 4
                  }
                },
                {
                  classNames: 'columns is-multiline is-mobile',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemNumberBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemNumberConfig,
                            text: '1'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Either you start on your own and invest a lot of time and money in a financing concept, of which you do not know 100% whether it is the right one for your personal situation.'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  classNames: 'columns is-multiline is-mobile',
                  ...itemContainerConfig,
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemNumberBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemNumberConfig,
                            text: '2'
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Or you consult an expert and thereby take the safe route. Benefit from the experience of a professional who will achieve best conditions for you in real estate financing, while you can fully concentrate on your choice of real estate, as well as your job or your family.'
                          }
                        }
                      ]
                    }
                  ]
                },
                {
                  type: 'text',
                  config: {
                    margin: '30px 0 35px',
                    defaultCss: {
                      media: [
                        {
                          breakpoint: 'mobile',
                          css: { color: '#fff' }
                        }
                      ]
                    },
                    text:
                      '➞ By working with me as an intermediary, you gain access to a large network of banks and credit institutions and can choose the <b>best possible conditions</b> between all providers - fast & uncomplicated'
                  }
                },
                {
                  type: 'cta',
                  config: {
                    margin: {
                      desktop: '10px 0 10px',
                      tablet: '10px 0 0',
                      mobile: '10px 0 0'
                    },
                    ...ctaButton,
                    link: ''
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
