import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const itemDescriptionConfig = {
  defaultCss: {
    textAlign: 'left'
  },
  fontSize: {
    desktop: '16px',
    tablet: '16px',
    mobile: '16px'
  },
  margin: {
    desktop: '0 0 20px',
    tablet: '0 0 20px',
    mobile: '0 0 20px'
  }
}

const itemIconConfig = {
  size: 5,
  icon: 'done',
  defaultCss: {
    color: 'primary'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      dark: false,
      defaultCss: {
        backgroundImage: "url('/images/bg.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center'
      },
      containers: [
        {
          classNames: 'container',
          padding: {
            fullHd: '40px 0 80px',
            widescreen: '40px 0 80px',
            desktop: '40px 40px 80px',
            tablet: '40px 40px 60px',
            mobile: '30px 20px 40px'
          },
          containers: [
            {
              classNames: 'columns is-multiline',
              containers: [
                {
                  classNames: 'column is-full',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        size: 6,
                        fontSize: {
                          desktop: '14px',
                          tablet: '14px',
                          mobile: '12px'
                        },
                        margin: {
                          desktop: '0 0 10px',
                          tablet: '0 0 10px',
                          mobile: '0 0 10px'
                        },
                        text: 'Teure Fehler beim Immobilienkauf vermeiden:'
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        size: 1,
                        fontSize: {
                          desktop: '32px',
                          tablet: '32px',
                          mobile: '20px'
                        },
                        margin: 0,
                        defaultCss: {
                          lineHeight: '1.2'
                        },
                        text:
                          'Erhalten Sie innerhalb von 24 Stunden Ihr persönliches Finanzierungskonzept, durch das Sie Ihre Wunschimmobilie zu Best-Konditionen bekommen'
                      }
                    },
                    {
                      type: 'hr',
                      config: {
                        height: 4,
                        width: '120px',
                        defaultCss: {
                          color: 'primary'
                        },
                        margin: {
                          desktop: '20px auto',
                          tablet: '20px auto',
                          mobile: '20px auto'
                        }
                      }
                    },
                    {
                      type: 'text',
                      config: {
                        defaultCss: {
                          textAlign: 'center'
                        },
                        fontSize: {
                          desktop: '16px',
                          tablet: '16px',
                          mobile: '16px'
                        },
                        margin: {
                          desktop: '0 120px 20px',
                          tablet: '0 0 20px',
                          mobile: '0 0 10px'
                        },
                        text:
                          'Ich erstelle mit Ihnen gemeinsam einen <b>attraktiven Finanzierungsplan</b> und übernehme das <b>komplette</b> Finanzierungskonzept für Ihre Immobilie.'
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  containers: [
                    {
                      type: 'video',
                      config: {
                        url: 'https://vimeo.com/526840202?=xdr4',
                        poster:
                          'https://videoapi-muybridge.vimeocdn.com/animated-thumbnails/image/7ff06750-18b6-44db-b15e-8e7433e10b1f.gif?ClientID=vimeo-core-prod&Date=1622090987&Signature=2ed314588d256e041593be4aa3bdd497673e89d7'
                      }
                    },
                    {
                      classNames: 'is-hidden-mobile',
                      margin: '20px',
                      defaultCss: {
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center'
                      },
                      containers: [
                        {
                          type: 'image',
                          config: {
                            src: '/images/whofinance.png',
                            width: '200px'
                          }
                        },
                        {
                          type: 'image',
                          config: {
                            src: '/images/whofinance-video.png',
                            width: '300px'
                          }
                        }
                      ]
                    },
                    {
                      type: 'cta',
                      config: {
                        classNames: 'is-hidden-tablet',
                        dark: false,
                        margin: {
                          desktop: '30px 0 0',
                          tablet: '30px 0 0',
                          mobile: '30px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                },
                {
                  classNames: 'column is-12-mobile is-6-tablet',
                  containers: [
                    {
                      type: 'text',
                      config: {
                        text:
                          'Entdecken Sie, worauf es bei der Finanzierung einer Immobilie <u>wirklich</u> ankommt und sichern Sie sich jetzt professionelle Unterstützung bei allen wichtigen Entscheidungen.',
                        ...itemDescriptionConfig
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Bessere Konditionen:</b> Durch mein umfangreiches Netzwerk öffne ich Ihnen Türen, die Ihnen Geld sparen',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Schnelle Ergebnisse</b>: In unserem Erstgespräch erhalten Sie ein Feedback zur generellen Machbarkeit',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'textWithIcon',
                      config: {
                        descriptionConfig: {
                          text:
                            '<b>Mehr Sicherheit</b>: Durch mein Finanzierungskonzept ist Ihr Prozess planbar und überschaubar. Sie behalten jederzeit die volle Kontrolle.',
                          ...itemDescriptionConfig,
                          fontSize: {
                            desktop: '16px',
                            tablet: '16px',
                            mobile: '16px'
                          }
                        },
                        iconConfig: {
                          ...itemIconConfig
                        }
                      }
                    },
                    {
                      type: 'cta',
                      config: {
                        dark: false,
                        full: true,
                        margin: {
                          desktop: '10px 0 0',
                          tablet: '10px 0 0',
                          mobile: '10px 0 0'
                        },
                        ...ctaButton
                      }
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
