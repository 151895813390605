import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-5-tablet is-5-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/sabine-krauss.jpg',
                    width: '100%'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      marginTop: '5px'
                    },
                    text: 'Sabine Krauß - Expertin für Baufinanzierung'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-7-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    margin: 0,
                    defaultCss: {
                      color: '#000',
                      textAlign: 'left'
                    },
                    text: 'Erfahrene Partnerin'
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    defaultCss: {
                      fontWeight: '500',
                      color: '#000',
                      textAlign: 'left'
                    },
                    text: 'im Bereich Finanzen & Immobilen'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px 0 30px',
                      tablet: '30px 0 30px',
                      mobile: '30px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'Ich war über 30 Jahre im Hause der Deutschen Bank tätig, 20 Jahre als Firmenkundenbetreuerin in diversen Filialen im In- und Ausland, und arbeite heute als selbstständige Finanzberaterin bei der FIBA im Bereich Baufinanzierung.<br/><br/> Um für meine Kunden unter der Vielzahl von Banken & Versicherern den optimalen Partner und die besten Konditionen auszuhandeln, agiere ich komplett unabhängig. <br/><br/> Ich weiß genau, in welcher Situation Sie sich gerade befinden, denn ich kenne den Immobilienmarkt in- und auswendig. Erfahrungen habe ich außerdem durch diverse eigene Immobilien sammeln können.<br/><br/> Als Kunde profitieren Sie von meiner Erfahrung aus ca. 15 Mio. € Baufinanzierungsvolumen pro Jahr, in Höhe von über 200 Mio. € Gesamtvolumen. <br/><br/>Daher ist eine Zusammenarbeit mit mir kein Risiko, <b>denn ich nutze bewährte Systeme, die in der Praxis funktionieren</b>.',
                    defaultCss: {
                      color: '#000',
                      textAlign: 'left'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
