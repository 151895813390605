import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          classNames: 'columns is-multiline',
          containers: [
            {
              classNames: 'column is-12-mobile is-5-tablet is-5-desktop',
              containers: [
                {
                  type: 'image',
                  config: {
                    src: '/images/sabine-krauss.jpg',
                    width: '100%'
                  }
                },
                {
                  type: 'text',
                  config: {
                    defaultCss: {
                      marginTop: '5px'
                    },
                    text: 'Sabine Krauß - Expert for construction financing'
                  }
                }
              ]
            },
            {
              classNames: 'column is-12-mobile is-7-desktop',
              containers: [
                {
                  type: 'text',
                  config: {
                    size: 2,
                    margin: 0,
                    defaultCss: {
                      color: '#000',
                      textAlign: 'left'
                    },
                    text: 'Experienced partner'
                  }
                },
                {
                  type: 'text',
                  config: {
                    size: 3,
                    defaultCss: {
                      fontWeight: '500',
                      color: '#000',
                      textAlign: 'left'
                    },
                    text: 'in the area of finance & real estate'
                  }
                },
                {
                  type: 'hr',
                  config: {
                    height: 4,
                    width: '120px',
                    defaultCss: {
                      color: 'primary'
                    },
                    margin: {
                      desktop: '30px 0 30px',
                      tablet: '30px 0 30px',
                      mobile: '30px 0 30px'
                    }
                  }
                },
                {
                  type: 'text',
                  config: {
                    text:
                      'I worked for Deutsche Bank for over 30 years, 20 years as a corporate client consultant in various branches in Germany and abroad. Now I work as an independent financial consultant at FIBA in the area of real estate financing.<br/><br/>In order to negotiate the optimal financing partner and the best conditions for my clients among the multiple banks & insurers, I act completely independent.<br/><br/>Furthermore I know exactly what situation you are in, because I know the real estate market inside out. I also have been able to gain experience through various properties of my own.<br/><br/>As a customer, you benefit from my experience from approximately 15 million € construction financing volume per year, amounting to a total volume of over 200 million €.<br/><br/>That is why working with me is not a risk as <b>I use proven systems that work in practice</b>.',
                    defaultCss: {
                      color: '#000',
                      textAlign: 'left'
                    }
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
