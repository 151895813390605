// @ts-nocheck
import { ContentRenderer } from '@peakconcepts/landingpage-kit'
import de_content from './de_content'
import en_content from './en_content'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './index.css'

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route path="/start">
            <ContentRenderer content={en_content} />
          </Route>
          <Route path="*">
            <ContentRenderer content={de_content} />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default App
