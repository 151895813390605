import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'
import ctaButton from './ctaButton'

const titleConfig = {
  size: 5,
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  },
  defaultCss: {
    textAlign: 'left'
  }
}

const descriptionConfig = {
  fontSize: {
    desktop: '18px',
    tablet: '16px',
    mobile: '14px'
  },
  margin: {
    desktop: '0 0 40px',
    tablet: '0 0 40px',
    mobile: '0 0 40px'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            text: 'Any questions?',
            size: 2
          }
        },
        {
          type: 'hr',
          config: {
            height: 4,
            width: '150px',
            defaultCss: {
              color: 'primary'
            },
            margin: {
              desktop: '30px auto 50px',
              tablet: '30px auto 50px',
              mobile: '30px auto 40px'
            }
          }
        },
        {
          type: 'text',
          config: {
            text: 'Why do I get better conditions this way with you?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'I work completely independent and therefore have the possibility to choose the best provider for you.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'What does the service cost?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Basically, my service is completely free of charge for you. While the bank consultant receives his salary, I receive a commission in case of success - with the further advantage: I work independently and therefore you receive the best financing concept. The commission is not paid by you, but by the financing bank. Therefore the service is 100% free of charge for you.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'Which requirements do I have to fulfill?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'For working together, you must have a positive Schufa report and a residence permit in Germany.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text: 'Which amount of a credit can I get for a house?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'The amount of your credit always depends individually on the respective bank and your personal situation. I will be happy to support you in finding a property that ideally suits your financial situation and will design a tailor-made financing concept that is precisely tailored to your wishes.',
            ...descriptionConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Is it also possible to receive financing without own capital?',
            ...titleConfig
          }
        },
        {
          type: 'text',
          config: {
            text:
              'Basically yes, but I need exact data from you to be able to make a reliable statement. You will find out which plan allows financing without equity capital and which conditions you can expect in our initial meeting.',
            ...descriptionConfig,
            margin: {
              desktop: '0 0 20px',
              tablet: '0 0 20px',
              mobile: '0 0 20px'
            }
          }
        },
        {
          type: 'cta',
          config: {
            dark: false,
            text: ctaButton.text,
            subline: ctaButton.subline,
            link: ctaButton.link,
            margin: {
              desktop: '50px 0 0',
              tablet: '50px 0 0',
              mobile: '30px 0 0'
            }
          }
        }
      ]
    }
  ]
}

export default data
