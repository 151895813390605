const general = {
  title: 'Sabine Krauß » Unproblematische Baufinanzierung.',
  description:
    'Wie Sie Ihre Wunschimmobilie innerhalb von 4 Wochen zu Bestkonditionen finanzieren',
  author: '',
  publisher: '',
  revisitAfter: '7 days',
  type: 'website',
  image: 'images/share.jpg',
  site: '',
  twitterCard: 'summary_large_image',
  creator: ''
}

const data = {
  main: {
    ...general
  },
  imprint: {
    ...general,
    title:
      'Impressum » Wie Sie Ihre Wunschimmobilie innerhalb von 4 Wochen zu Bestkonditionen finanzieren'
  },
  policy: {
    ...general,
    title:
      'Datenschutz » Wie Sie Ihre Wunschimmobilie innerhalb von 4 Wochen zu Bestkonditionen finanzieren'
  },
  thankYou: {
    ...general,
    title:
      'Danke » Wie Sie Ihre Wunschimmobilie innerhalb von 4 Wochen zu Bestkonditionen finanzieren'
  }
}

export default data
