// @ts-nocheck
import { Content, ContainerBox } from '@peakconcepts/landingpage-kit'
import colors from './content/data/colors'

// Defaults
import defaultSettings from './content/default/de_defaultSettings'
import defaultPages from './content/default/de_defaultPages'

// Content Data
import seoHeaderData from './content/data/de/seoHeader'
import shortHeroHeaderData from './content/data/de/hero'
import openingData from './content/data/de/opening'
import twoOptionsData from './content/data/de/twoOptions'
import howItWorksData from './content/data/de/howItWorks'
import solutionData from './content/data/de/solution'
import advantagesData from './content/data/de/advantages'
import infoData from './content/data/de/info'
import resultsData from './content/data/de/results'
import aboutUsData from './content/data/de/aboutUs'
import quoteData from './content/data/de/quote'
import processData from './content/data/de/process'
import faqsData from './content/data/de/faqs'

const content: Content = {
  ...defaultSettings,
  pages: [
    {
      path: '/',
      headerEnabled: true,
      headerConfig: {
        positionType: 'absolute',
        fixed: false,
        backgroundColor: colors.secondaryColor
      },
      seoHeaderConfig: seoHeaderData.main,
      sections: [
        {
          component: ContainerBox,
          data: {
            ...shortHeroHeaderData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...openingData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...twoOptionsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...howItWorksData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...solutionData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...advantagesData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...quoteData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...aboutUsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...infoData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...resultsData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...processData
          }
        },
        {
          component: ContainerBox,
          data: {
            ...faqsData
          }
        }
      ]
    },
    ...defaultPages
  ]
}
export default content
