import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemNumberBoxClassNames =
  'column is-2-mobile is-1-tablet is-1-desktop pt-0 pb-0'
const itemTextBoxClassNames =
  'column is-10-mobile is-11-tablet is-11-desktop pl-0 pb-6 pt-0'

const itemDotsContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center'
  }
}

const itemTextBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column'
  }
}

const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const data: ContainerBoxInterface = {
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            margin: {
              fullHd: '0 0 40px',
              widescreen: '0 0 40px',
              desktop: '0 0 40px',
              tablet: '0 0 20px',
              mobile: '0 0 20px'
            },
            size: 2,
            text: 'Wie läuft die Zusammenarbeit mit mir ab?',
            defaultCss: {
              marginBottom: '40px',
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            margin: {
              fullHd: '30px auto',
              widescreen: '30px auto',
              desktop: '30px auto',
              tablet: '20px auto',
              mobile: '20px auto'
            },
            defaultCss: {
              color: 'primary'
            },
            height: 4
          }
        },
        {
          padding: {
            fullHd: '70px 0 0 0',
            widescreen: '70px 0 0 0',
            desktop: '70px 0 0 0',
            tablet: '30px 0 0 0',
            mobile: '30px 0 0 0'
          },
          classNames: 'columns is-multiline is-mobile',
          containers: [
            {
              defaultCss: {
                display: 'flex'
              },
              containers: [
                {
                  classNames: itemNumberBoxClassNames,
                  ...itemDotsContainer,
                  containers: [
                    {
                      type: 'dots',
                      config: {
                        color: 'rgb(255,170,0)',
                        dotConfig: {
                          dotSize: 2
                        }
                      }
                    }
                  ]
                },
                {
                  classNames: itemTextBoxClassNames,
                  ...itemTextBoxContainer,
                  containers: [
                    {
                      type: 'text',
                      config: {
                        ...itemDescriptionConfig,
                        text:
                          'In einem persönlichen Gespräch finden wir gemeinsam heraus, was Ihnen bei der Immobilienfinanzierung wirklich wichtig ist. Sie erhalten eine umfangreiche Beratung, die <u>individuell auf Ihre Situation</u> zugeschnitten ist. Darüber hinaus erhalten Sie die professionelle Meinung einer erfahrenen Expertin zu Ihrer Immobilienfinanzierung.'
                      }
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Im zweiten Gespräch entwerfen wir <u>basierend auf Ihren Zielen</u> ein klares Konzept, wie Sie Ihre Immobilie optimal finanzieren können. Ich zeige Ihnen verschiedene Möglichkeiten auf und gehe mit Ihnen alternative Wege durch, welche für Sie in Frage kommen. Um Ihnen einen Überblick zu verschaffen, stelle ich Ihnen sämtliche Vor- und Nachteile der jeweiligen Finanzierungskonzepte dar und berate Sie seriös und zuverlässig in Bezug auf die weitere Vorgehensweise.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            },
            {
              containers: [
                {
                  defaultCss: {
                    display: 'flex'
                  },
                  containers: [
                    {
                      classNames: itemNumberBoxClassNames,
                      ...itemDotsContainer,
                      containers: [
                        {
                          type: 'dots',
                          config: {
                            showLines: false,
                            color: 'rgb(255,170,0)',
                            dotConfig: {
                              dotSize: 2
                            }
                          }
                        }
                      ]
                    },
                    {
                      classNames: itemTextBoxClassNames,
                      ...itemTextBoxContainer,
                      containers: [
                        {
                          type: 'text',
                          config: {
                            ...itemDescriptionConfig,
                            text:
                              'Nachdem wir gemeinsam den optionalen Prozess ausgearbeitet haben, helfe ich Ihnen zeitnah bei der kompletten Umsetzung. Ich übernehme den vollen Verwaltungsaufwand und halte Ihnen den Rücken frei. Natürlich genießen Sie während des <u>kompletten Prozesses</u> absolute Transparenz, denn ich informiere Sie über jeden Schritt und sprechen diesen mit Ihnen ab. Schlussendlich treffen Sie mit mir zusammen eine Entscheidung und beginnen mit der Finanzierung Ihrer Traumimmobilie.'
                          }
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        },
        {
          classNames: 'column is-12-mobile is-12-desktop pl-0 pr-0',
          containers: [
            {
              type: 'text',
              config: {
                margin: '0',
                text:
                  '<b>WICHTIG:</b> Meine Dienstleistung ist für Sie bis zum Abschluss zu <u>100 % kostenlos</u>. Denn ich werde NUR für meine Erfolge bezahlt. Erst wenn Sie sich für eine Option entschieden haben, mit der Sie wirklich zufrieden sind, und diese von der Bank oder der Versicherung genehmigt ist, erhalte ich eine Provision von dem Finanzierungspartner.'
              }
            }
          ]
        }
      ]
    }
  ]
}

export default data
