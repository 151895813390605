import { ContainerBoxInterface } from '@peakconcepts/landingpage-kit'

const itemNumberBoxClassNames =
  'column is-2-mobile is-4-tablet is-offset-1-desktop is-1-desktop'
const itemTextBoxClassNames = 'column is-10-mobile is-8-tablet is-9-desktop'

const itemNumberBoxContainer = {
  defaultCss: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    media: [
      {
        breakpoint: 'tablet',
        css: { justifyContent: 'flex-start' }
      }
    ]
  }
}

const itemContainerConfig = {
  defaultCss: {
    margin: '0 0 10px !important',
    media: [
      {
        breakpoint: 'tablet',
        css: { margin: '0 !important' }
      }
    ]
  }
}

const itemNumberConfig = {
  size: 4,
  fontSize: {
    fullHd: '96px',
    widescreen: '96px',
    desktop: '96px',
    tablet: '60px',
    mobile: '36px'
  },
  margin: {
    desktop: '0 0 0 -70px',
    tablet: '0 0 0 -10px',
    mobile: '0 0 0 -10px'
  },
  defaultCss: {
    color: '#fa0',
    lineHeight: '1',
    textAlign: 'left'
  }
}
const itemDescriptionConfig = {
  margin: {
    desktop: '0',
    tablet: '0',
    mobile: '0'
  }
}

const itemTitleConfig = {
  size: 3,
  defaultCss: {
    textAlign: 'left'
  },
  margin: {
    desktop: '0 0 10px',
    tablet: '0 0 10px',
    mobile: '0 0 10px'
  }
}

const data: ContainerBoxInterface = {
  defaultCss: {
    backgroundImage:
      "linear-gradient(rgba(255,255,255,0.9), rgba(255,255,255,0.95)), url('/images/6.png')",
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  containers: [
    {
      classNames: 'container',
      padding: {
        fullHd: '80px 0',
        widescreen: '80px 0',
        desktop: '80px 40px',
        tablet: '60px 40px',
        mobile: '40px 20px'
      },
      containers: [
        {
          type: 'text',
          config: {
            margin: 0,
            size: 2,
            text: 'Your advantages at a glance',
            defaultCss: {
              textAlign: 'center'
            }
          }
        },
        {
          type: 'hr',
          config: {
            margin: {
              desktop: '30px auto 60px',
              tablet: '30px auto 30px',
              mobile: '30px auto 30px'
            },
            defaultCss: {
              color: 'primary'
            },
            height: 4
          }
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '01'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'Full control'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'My concept gives you an X-ray view of all costs because I provide you with all figures, data & facts. You keep the overview at all times and have a clear plan, through which you know exactly what is coming up.'
                  }
                }
              ]
            }
          ]
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '02'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'More Security'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'By working with me as an expert, you completely eliminate the risk of making expensive mistakes. You benefit from my experience of over 30 years in the financial industry, including 15 years in real estate financing, and use methods designed specifically for real estate buyers.'
                  }
                }
              ]
            }
          ]
        },
        {
          classNames: 'columns is-multiline is-mobile',
          ...itemContainerConfig,
          containers: [
            {
              classNames: itemNumberBoxClassNames,
              ...itemNumberBoxContainer,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemNumberConfig,
                    text: '03'
                  }
                }
              ]
            },
            {
              classNames: itemTextBoxClassNames,
              containers: [
                {
                  type: 'text',
                  config: {
                    ...itemTitleConfig,
                    text: 'Best conditions'
                  }
                },
                {
                  type: 'text',
                  config: {
                    ...itemDescriptionConfig,
                    text:
                      'Thanks to my network of over 400 banks and insurance companies, you will receive the ideal conditions for optimal real estate financing. You save a lot of money, because you can choose the most favorable bank from a variety of providers.'
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
}

export default data
